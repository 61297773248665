:root {
  --mid-gray: #555555;
  --dark-gray: #2d2d2d;
  --delete-red: #ff0077;
  --updated-yellow: #ff9900;
  --add-blue: #00afff;
}

html,
body {
  width: 100%;
  min-width: 50em;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.nav {
  width: 100%;
  height: 3em;
  display: flex;
  padding: 0 3em;
  align-items: center;
  background-color: black;
  box-sizing: border-box;
  justify-content: space-between;
}

.nav-left {
  width: 90%;
  display: flex;
}

.nav-right {
  display: flex;
  width: 10%;
  justify-content: flex-end;
}

.nav-left a {
  text-decoration: none;
  color: white;
  margin: 0 2em 0 0;
}

.nav-right a {
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  text-decoration: none;
}

.pages {
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  padding: 2em 4em 5em;
  background: url("../public/images/school_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}

.container {
  width: 100%;
  min-width: 40em;
  margin: 0 auto;
  padding: 2em 2em 4em;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
}

.forms {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container table {
  width: 80%;
  margin: 0 auto;
}

.container table thead {
  font-weight: bold;
}

.container table td {
  padding: 1em 0;
  vertical-align: top;
}

.container table td .addCourseBtn {
  color: var(--add-blue);
  cursor: pointer;
}

.container table td .delCourseBtn {
  color: var(--delete-red);
  cursor: pointer;
}

.deleteCourse,
.delete-student {
  color: var(--delete-red);
  cursor: pointer;
}

.update-student {
  color: var(--updated-yellow);
  cursor: pointer;
}

form.form {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.form form {
  margin: 0 0 3em;
}

.assignments form {
  margin: 0;
}

form.form input,
form.form button {
  margin: 0.5em 0;
}

.form form {
  width: 100%;
}
.form form input,
.form form select,
.form form button {
  margin: 0.5em;
  background-color: white;
  border: 1px rgb(200, 200, 200) solid;
  padding: 0.5em;
}

.form form button {
  background-color: var(--mid-gray);
  color: white;
  padding: 0.5em 1em;
}

.password-warning {
  color: var(--delete-red)
}
.dropdown {
  width: 10em;
}

.dropdown select option {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
}

.scroll {
  width: 100%;
  overflow-x: scroll;
  padding: 1em 1em 3em;
}

.delete-form-page {
  width: 100%;
}
.delete-form-page select {
  margin: 0 0.5em;
  background-color: white;
  border: 1px rgb(200, 200, 200) solid;
  padding: 0.5em;
}

.delete-form-page button {
  background-color: var(--mid-gray);
  color: white;
  padding: 0.5em 1em;
  border: none;
}

.popup {
  position: absolute;
  top: 5%;
  left: 20%;
  width: 30em;
  min-height: 15em;
  margin: 0 auto;
  flex-direction: column;
  background-color: var(--dark-gray);
  justify-content: center;
  align-items: flex-start;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.8);
  padding: 0 4em 2em;
  box-sizing: border-box;
  color: white;
}

.popup h2 {
  padding: 0;
  margin: 0.5em 0;
}
.popup select,
.popup select option {
  background-color: white;
  width: 15em;
  height: 3em;
}

.popup .clickRemoveCourse {
  cursor: pointer;
}

.popup .clickRemoveCourse:hover {
  text-decoration: underline;
}

.popup .close {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}

.view-enrollments {
  text-decoration: underline;
  cursor: pointer;
}

.view-enrollments:hover {
  color: var(--add-blue);
}

.grades table {
  border-collapse: collapse;
  width: auto;
  margin-left: 0;
}

.grades table tr td {
  border: 1px var(--dark-gray) solid;
  padding: 0.5em;
  font-size: 1em;
  width: 8em;
}

button:active {
  transform: translateY(2px);
}

.login-error-msg {
  color: var(--delete-red);
  margin: 0 0 0.5em;
}
